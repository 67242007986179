import { Fragment, useEffect, useRef, useState } from "react";
import {
  expirationHandler,
  formatDate,
  instance,
  serialize,
  twoDigits,
  waitForToken,
} from "../../utils";
import Select from "../../components/Select";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../components/Modal";
import UserModalBody from "./UserModalBody";
import Swal from "sweetalert2";
import Checkbox from "../../components/Checkbox";
import Dropdown from "../../components/Dropdown";
import LoadingScreen from "../../components/LoadingScreen";
import FileInput from "../../components/FileInput";
import ImportErrorForm from "./ImportErrorForm";
import { AnimatePresence } from "framer-motion";
import TablePage from "../../components/TablePage";
import { useDispatch, useSelector } from "react-redux";
import RowDetail from "../../components/RowDetail";
import CostumerRow from "./CostumerRow";
import { Link, useNavigate } from "react-router-dom";

const PerpanjangForm = ({
  paymentMethodState,
  buktiPembayaranState,
  strategyState,
  lunasState,
  userForm,
  submitPerpanjang,
  bayar,
}) => {
  const [strategy, setStrategy] = strategyState;
  const [buktiPembayaran, setBuktiPembayaran] = buktiPembayaranState;
  const [paymentMethod, setPaymentMethod] = paymentMethodState;
  const [lunas, setLunas] = lunasState;
  const [perpanjang, perpanjangBulk] = submitPerpanjang;
  const isTransfer = paymentMethod === "transfer";
  const isCash = paymentMethod === "cash";

  const nextMonth = () => {
    const date = strategy ? new Date(userForm.aktifHingga) : new Date();
    date.setMonth(date.getMonth() + 1);
    return (
      twoDigits(date.getDate()) +
      "-" +
      twoDigits(date.getMonth() + 1) +
      "-" +
      date.getFullYear()
    );
  };

  const isBulk = isNaN(userForm.id);

  return (
    <form
      onSubmit={
        !isNaN(userForm.id)
          ? userForm.lunas
            ? perpanjang
            : bayar
          : perpanjangBulk
      }
      id="perpanjang"
      className="flex flex-wrap items-center"
    >
      {userForm.lunas ? (
        <div className="flex flex-wrap items-center w-full">
          <Select
            options={[
              { value: true, label: "Perpanjang" },
              { value: false, label: "Reset" },
            ]}
            onChange={(value) => setStrategy(value)}
            value={strategy}
          />
          {isBulk ? undefined : (
            <div className="w-1/2 px-2">Aktif Hingga: {nextMonth()}</div>
          )}
          <div className="border-t mt-4 border-slate-400 w-full"></div>
        </div>
      ) : null}
      {lunas ? (
        <div className="w-full">
          <div className="w-full pt-4 relative">
            <div className="absolute opacity-0 z-[-1] flex justify-center items-center mt-2 w-full">
              <input
                required
                className="focus-visible:outline-none"
                type="text"
                value={paymentMethod}
                onChange={() => {}}
              />
            </div>
            <div className="w-full flex">
              <div className="w-1/2 pr-2">
                <Button
                  className={
                    "w-full" +
                    (isCash ? " outline outline-slate-300 outline-4" : "")
                  }
                  variant={isCash ? "dark" : "light"}
                  onClick={() => {
                    setPaymentMethod("cash");
                    setBuktiPembayaran(null);
                  }}
                >
                  Cash
                </Button>
              </div>
              <div className="w-1/2 pl2">
                <Button
                  className={
                    "w-full" +
                    (isTransfer ? " outline outline-slate-300 outline-4" : "")
                  }
                  variant={isTransfer ? "dark" : "light"}
                  onClick={() => {
                    setPaymentMethod("transfer");
                    setBuktiPembayaran(null);
                  }}
                >
                  Transfer
                </Button>
              </div>
            </div>
          </div>
          {isTransfer && !isBulk ? (
            <FileInput
              text={"Choose\xa0File"}
              value={buktiPembayaran}
              onChange={(e) => setBuktiPembayaran(e.target.files[0])}
              accept="image/*"
            />
          ) : undefined}
        </div>
      ) : null}
      {userForm.lunas ? (
        <div className="mt-2 flex items-center">
          <input
            onChange={(e) => {
              setLunas(e.target.checked);
              setBuktiPembayaran(null);
              setPaymentMethod("");
            }}
            checked={lunas}
            id="lunas"
            type="checkbox"
          />
          <label htmlFor="lunas" className="ml-2 pb-1 leading-none select-none">
            Lunas
          </label>
        </div>
      ) : null}
    </form>
  );
};

const ImportErrorModal = ({ close, data, submitForm }) => {
  const formRef = useRef(null);

  const daerahPaket = data.daerahs[0] && data.pakets[0];

  return (
    <Modal
      size={daerahPaket ? "xl" : data.pakets[0] ? "sm" : "xs"}
      title="Import Error!"
      close={close}
      body={
        <ImportErrorForm ref={formRef} data={data} submitForm={submitForm} />
      }
      footer={
        <div className="w-full flex justify-between">
          <Button variant="light" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="dark"
            type="submit"
            form="importErrorForm"
            onClick={() => formRef.current.checkForm()}
          >
            Submit
          </Button>
        </div>
      }
    />
  );
};
const SendMessageForm = ({ messageSend, onSubmit }) => {
  const [message, setMessage] = useState(messageSend);
  return (
    <form
      id="message"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(message);
      }}
    >
      <textarea
        className="w-full mt-2 border border-gray-400 px-3 py-1 rounded-lg"
        rows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
    </form>
  );
};

export default function Costumer() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [modalType, setModalType] = useState(false);
  const emptyForm = {
    id: "",
    username: "",
    password: "",
    paket: "",
    aktifHingga: "",
    nama: "",
    namaKtp: "",
    daerah: "",
    alamat: "",
    nomor: "",
    ktp: "",
    notify: false,
    active: false,
    lunas: false,
    biaya: "",
    daerahObj: null,
    paketObj: null,
    hasPrefix: false,
  };
  const defaultOrder = { column: "last_updated", asc: false };
  const [userForm, setUserForm] = useState({ ...emptyForm });
  const dispatch = useDispatch();
  const [checkboxes, setCheckboxes] = [
    useSelector((state) => state.checkbox),
    (newVal) => dispatch({ type: "SET_CHECKBOX", payload: newVal }),
  ];
  const [temp, setTemp] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [strategy, setStrategy] = useState(true);
  const [lunas, setLunas] = useState(true);
  const [fileInput, setFileInput] = useState("");
  const [sortBy, setSort] = useState({ ...defaultOrder });
  const [whatsapp, setWhatsapp] = useState({
    status: false,
    message: "",
  });
  const navigate = useNavigate();
  const [prefix, setPrefix] = useState("") 
  const isMobile = useSelector((state) => state.isMobile);
  const userData = useSelector((state) => state.user);
  const isAdmin =
    (userData && (userData.role === "admin" || userData.role === "subadmin" || userData.role === "superadmin"));
  const mainCheckbox =
    checkboxes.length > 0 ? checkboxes.every(Boolean) : false;
  const checkSelected = () => {
    const res = [];
    checkboxes.forEach((x, idx) => {
      if (x) res.push(idx);
    });
    return res;
  };
  const selected = checkSelected();
  const isSelected = selected.length > 0;

  const resetCheckbox = () => {
    setCheckboxes(new Array(20).fill(false));
  };

  useEffect(() => {
    waitForToken(findCostumerStart);
  }, []);
  useEffect(() => {
    if (userData && userData.role === "admin") {
      console.log("test")
      instance.get("prefix").then(({data}) => {
        setPrefix(data)
      })  
    }
  }, [userData])

  const getParams = (obj = {}) =>
    serialize({
      search: searchInput,
      page: page - 1,
      column: sortBy.column,
      direction: sortBy.asc,
      ...obj,
    });

  const findCostumerStart = (search) => {
    setIsLoading(true);
    const param = {};
    if (search) {
      param.page = 0;
      setPage(1);
    }
    instance
      .get("costumer" + getParams(param))
      .then(({ data }) => {
        setResult(data);
        setIsLoading(false);
        setWhatsapp(data.whatsapp);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const setResult = ({ value, length }) => {
    resetCheckbox(value);
    setRows(value);
    setDataLength(length);
  };

  const openPerpanjang = (costumer) => {
    setFormToRow(costumer);
    setModalType("perpanjang");
  };

  const addCostumer = () => {
    const payload = {
      alamat: userForm.alamat,
      nama: userForm.nama,
      nama_ktp: userForm.namaKtp,
      ktp: userForm.ktp,
      username: userForm.username,
      password: userForm.password,
      DaerahId: userForm.daerah,
      PaketId: userForm.paket,
      aktifHingga: userForm.aktifHingga,
      nomor: userForm.nomor,
      biaya: userForm.biaya,
      notify: userForm.notify,
      RadiusGroupId: userData.grp,
    };
    setIsLoading(true);
    instance
      .post("costumer" + getParams(), payload)
      .then(({ data }) => {
        setIsLoading(false);
        setModalType(false);
        setResult(data);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Berhasil menambahkan pelanggan!",
        });
        setUserForm({ ...emptyForm });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const confirmEdit = () => {
    const payload = {
      alamat: userForm.alamat,
      nama: userForm.nama,
      namaKtp: userForm.namaKtp,
      username: userForm.username,
      ktp: userForm.ktp,
      password: userForm.password,
      aktifHingga: userForm.aktifHingga,
      nomor: userForm.nomor,
      DaerahId: userForm.daerah,
      PaketId: userForm.paket,
      notify: userForm.notify,
    };
    if (userForm.hasPrefix) payload.username = prefix + "_" + userForm.username;
    setIsLoading(true);
    instance
      .put("costumer/" + userForm.id, payload)
      .then(({ data }) => {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Berhasil merubah data",
        });
        setRows(
          rows.map((row) => {
            if (row.id === data.id) return data;
            return row;
          })
        );
        setModalType(false);
        setUserForm({ ...emptyForm });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const deleteCostumer = (row) => {
    Swal.fire({
      icon: "warning",
      title: "Hapus pelanggan " + row.username + "?",
      text: "Setelah menghapus data tidak bisa dipuplihkan lagi",
      confirmButtonText: "Confirm",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      focusConfirm: false,
      focusCancel: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;
      setIsLoading(true);
      const customParams = {};
      const noData = rows.length < 2 && page !== 1;
      if (noData) {
        customParams.page = page - 2;
      }
      instance
        .delete("costumer/" + row.id + getParams(customParams))
        .then(({ data }) => {
          setIsLoading(false);
          setUserForm({ ...emptyForm });
          setResult(data);
          if (noData) {
            setPage(-1);
          }
          Swal.fire({
            icon: "success",
            title: "Removed",
            text: "Telah berhasil menghapus pelanggan",
          });
        })
        .catch((e) => expirationHandler(e, setIsLoading));
    });
  };

  const setFormToRow = (data) => {
    setUserForm({
      ...data,
      paket: data.paket.id,
      daerah: data.daerah.id,
      paketObj: data.paket,
      daerahObj: data.daerah,
      aktifHingga: data.aktifHingga.split("T")[0],
    });
  };

  const bayar = (e) => {
    e.preventDefault();

    setIsLoading(true);
    const formData = new FormData();
    formData.append("paymentMethod", paymentMethod);
    formData.append("bukti", fileInput);
    instance.put("bayar/" + userForm.id).then(({ data }) => {
      setIsLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Telah melunaskan pelanggan",
        showCancelButton: true,
        cancelButtonText: "Tutup",
        confirmButtonText: "Print",
      }).then(({ isConfirmed }) => {
        if (!isConfirmed) return;
        printData(data.pembayaranId);
      });
      setRows(
        rows.map((x) => (x.id === data.id ? { ...data, lunas: true } : x))
      );
      setUserForm({ ...emptyForm });
      setModalType(false);
      setStrategy(true);
      setPaymentMethod("");
      setLunas(true);
    });
  };

  const perpanjang = (e) => {
    e.preventDefault();

    setIsLoading(true);
    const formData = new FormData();
    formData.append("strategy", strategy);
    formData.append("lunas", lunas);
    if (lunas) {
      formData.append("paymentMethod", paymentMethod);
      formData.append("bukti", fileInput);
    }
    instance
      .put("perpanjang/" + userForm.id, formData)
      .then(({ data }) => {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Masa aktif " + userForm.nama + " telah di perpanjang",
          showCancelButton: true,
          cancelButtonText: "Tutup",
          confirmButtonText: "Print",
        }).then(({ isConfirmed }) => {
          if (!isConfirmed) return;
          printData(data.pembayaranId);
        });
        setUserForm({ ...emptyForm });
        setModalType(false);
        setStrategy(true);
        setPaymentMethod("");
        setLunas(true);
        setRows(rows.map((x) => (x.id === data.id ? data : x)));
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };
  const perpanjangBulk = (e) => {
    e.preventDefault();
    const ids = selected.map((x) => rows[x].id);
    setIsLoading(true);
    instance
      .put("bulk-perpanjang", { strategy, ids, paymentMethod })
      .then(({ data }) => {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "masa aktif " + selected.length + " user telah diperpanjang",
        });
        setRows(rows.map((row) => data.find((x) => x.id === row.id) || row));
        setModalType(false);
        setStrategy(true);
        resetCheckbox();
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const toggleStatuses = (isEnable) => {
    const status = isEnable ? "Enable" : "Disable";
    Swal.fire({
      icon: "info",
      title: status,
      text: status + " " + selected.length + " Pelanggan?",
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;
      setIsLoading(true);
      const ids = selected.map((x) => rows[x].id);
      instance
        .put("costumer/" + status.toLowerCase() + serialize({ ids }))
        .then(({ data }) => {
          setIsLoading(false);
          setRows(rows.map((row) => data.find((x) => x.id === row.id) || row));
          resetCheckbox();
        })
        .catch((e) => expirationHandler(e, setIsLoading));
    });
  };

  const toggleStatus = (costumer, isEnable) => {
    const status = isEnable ? "Enable" : "Disable";
    Swal.fire({
      icon: "info",
      title: status,
      text: status + " Koneksi Milik " + costumer.nama + "?",
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;
      setIsLoading(true);
      instance
        .put(`costumer/${status.toLowerCase()}/${costumer.id}`)
        .then(({ data }) => {
          setIsLoading(false);
          setRows(rows.map((row) => (row.id === data.id ? data : row)));
        });
    });
  };

  const deleteBulk = () => {
    Swal.fire({
      icon: "warning",
      title: "Hapus " + selected.length + " pelanggan?",
      text: "Setelah menghapus data tidak bisa dipuplihkan lagi",
      confirmButtonText: "Confirm",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      focusConfirm: false,
      focusCancel: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;
      const ids = selected.map((x) => rows[x].id);
      setIsLoading(true);
      const customParams = { ids };
      const noData = mainCheckbox && page === dataLength && page !== 1;
      if (noData) {
        customParams.page = page - 2;
      }
      instance
        .delete("bulk-costumer" + getParams({ ids }))
        .then(({ data }) => {
          setIsLoading(false);
          setResult(data);
          if (noData) {
            setPage(page - 1);
          }
          Swal.fire({
            icon: "success",
            title: "Removed",
            text: "Telah berhasil menghapus " + selected.length + " pelanggan",
          });
        })
        .catch((e) => expirationHandler(e, setIsLoading));
    });
  };

  const editCostumer = (data) => {
    const hasPrefix = data.username.includes(prefix + "_");
    setUserForm({
      ...data,
      paket: data.paket.id,
      daerah: data.daerah.id,
      paketObj: data.paket,
      daerahObj: data.daerah,
      aktifHingga: data.aktifHingga.split("T")[0],
      username: hasPrefix ? data.username.split("_").slice(1).join("_") : data.username,
      hasPrefix
    });
    console.log(userForm.username, prefix + "_" ,hasPrefix)
    setModalType("userForm");
  };

  const setCheckbox = (val, idx) => {
    setCheckboxes(checkboxes.map((x, i) => (idx === i ? val : x)));
  };

  const importCostumer = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("search", searchInput);
    formData.append("page", page);
    formData.append("file", fileInput);
    formData.append("column", sortBy.column);
    formData.append("direction", sortBy.asc);
    instance
      .post("import-costumer", formData)
      .then(({ data }) => {
        setFileInput("");
        setModalType(false);
        setResult(data);
        setIsLoading(false);
      })
      .catch((e) => {
        expirationHandler(e, setIsLoading);
        if (!e.response.data.response) return;
        const { daerahs, pakets } = e.response.data.response;
        if (pakets[0] || daerahs[0]) {
          setTemp(e.response.data.response);
          setModalType("importError");
        }
      });
  };

  const importCostumerError = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("search", searchInput);
    formData.append("page", page);
    formData.append("file", fileInput);
    formData.append("data", JSON.stringify(data));
    formData.append("column", sortBy.column);
    formData.append("direction", sortBy.asc);
    instance
      .post("import-costumer-err", formData)
      .then(({ data }) => {
        setIsLoading(false);
        setModalType(false);
        setFileInput("");
        setResult(data);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const CurrentModal = () => {
    let closeModal;
    switch (modalType) {
      case "perpanjang":
        closeModal = () => {
          setModalType(false);
          setUserForm({ ...emptyForm });
          setStrategy(true);
          setLunas(true);
          setPaymentMethod("");
          setFileInput("");
        };

        return (
          <Modal
            close={closeModal}
            title={
              (userForm.lunas ? "Perpanjang " : "Bayar ") +
              (userForm.nama ? userForm.nama : "")
            }
            body={
              <PerpanjangForm
                paymentMethodState={[paymentMethod, setPaymentMethod]}
                buktiPembayaranState={[fileInput, setFileInput]}
                strategyState={[strategy, setStrategy]}
                submitPerpanjang={[perpanjang, perpanjangBulk]}
                lunasState={[lunas, setLunas]}
                userForm={userForm}
                bayar={bayar}
              />
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeModal}>
                  Cancel
                </Button>
                <Button variant="dark" type="submit" form="perpanjang">
                  {userForm.lunas ? "Submit" : "Bayar"}
                </Button>
              </div>
            }
          />
        );
      case "userForm":
        closeModal = () => {
          setModalType(false);
          setUserForm({ ...emptyForm });
        };
        return (
          <Modal
            close={closeModal}
            title={userForm.id ? "Edit User" : "Tambah User"}
            body={
              <UserModalBody
                userForm={userForm}
                setUserForm={setUserForm}
                prefix={prefix}
                onSubmit={userForm.id ? confirmEdit : addCostumer}
              />
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeModal}>
                  Cancel
                </Button>
                <Button variant="dark" type="submit" form="userForm">
                  Submit
                </Button>
              </div>
            }
          />
        );
      case "import":
        closeModal = () => {
          setModalType(false);
          setFileInput("");
        };
        return (
          <Modal
            close={closeModal}
            title="Import Pelanggan"
            body={
              <form id="importForm" onSubmit={importCostumer}>
                <FileInput
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  value={fileInput}
                  onChange={(e) => setFileInput(e.target.files[0])}
                  required
                />
              </form>
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeModal}>
                  Cancel
                </Button>
                <Button variant="dark" type="submit" form="importForm">
                  Submit
                </Button>
              </div>
            }
          />
        );
      case "importError":
        closeModal = () => {
          setModalType(false);
          setTemp(null);
          setFileInput("");
        };
        return (
          <ImportErrorModal
            data={temp}
            close={closeModal}
            submitForm={importCostumerError}
          />
        );
      case "mobileDetail":
        closeModal = () => {
          setModalType(false);
          setUserForm({ ...emptyForm });
        };

        return (
          <RowDetail
            title={userForm.nama}
            close={closeModal}
            right={{
              title: "Action",
              component: (
                <div className="flex flex-wrap w-full justify-center">
                  <div className="w-1/2 px-1 py-1">
                    <Button
                      className="w-full"
                      onClick={() => {
                        setModalType("perpanjang");
                      }}
                    >
                      <FontAwesomeIcon
                        icon="bolt-lightning"
                        className="w-full"
                      />
                      Perpanjang
                    </Button>
                  </div>
                  <div className="w-1/2 px-1 py-1">
                    <Button
                      className="w-full"
                      disabled={!userForm.pembayaranId}
                      onClick={() => {
                        printData(userForm.pembayaranId);
                      }}
                    >
                      <FontAwesomeIcon icon="print" className="w-full" />
                      Print
                    </Button>
                  </div>
                  {isAdmin && (
                    <Fragment>
                      <div className="w-1/2 px-1 py-1">
                        <Link
                          to="/costumer_form"
                          state={{ userData: { ...userForm } }}
                        >
                          <Button className="w-full">
                            <FontAwesomeIcon
                              icon="pen-to-square"
                              className="w-full"
                            />
                            Edit
                          </Button>
                        </Link>
                      </div>
                      <div className="w-1/2 px-1 py-1">
                        <Button
                          className="w-full"
                          onClick={() => deleteCostumer(userForm)}
                        >
                          <FontAwesomeIcon icon="trash" className="w-full" />
                          Remove
                        </Button>
                      </div>
                    </Fragment>
                  )}
                  {whatsapp.status && (
                    <div className="w-1/2 px-1 py-1">
                      <Button
                        className="w-full"
                        onClick={() => setModalType("sendMessage")}
                      >
                        <FontAwesomeIcon icon="message" className="w-full" />
                        Kirim
                      </Button>
                    </div>
                  )}
                </div>
              ),
            }}
            left={{
              title: "Detail",
              component: (
                <div className="">
                  <table className="border border-slate-900 border-collapse w-full">
                    <tbody>
                      <tr>
                        <td className="border border-slate-900 px-1">Nama</td>
                        <td className="border border-slate-900 px-1">
                          {userForm.nama}
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-slate-900 px-1">
                          Username
                        </td>
                        <td className="border border-slate-900 px-1">
                          {userForm.username}
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-slate-900 px-1">KTP</td>
                        <td className="border border-slate-900 px-1">
                          {userForm.ktp}
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-slate-900 px-1">
                          Nama Sesuai KTP
                        </td>
                        <td className="border border-slate-900 px-1">
                          {userForm.namaKtp}
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-slate-900 px-1">Nomor</td>
                        <td className="border border-slate-900 px-1">
                          {userForm.nomor}
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-slate-900 px-1">
                          Password
                        </td>
                        <td className="border border-slate-900 px-1">
                          {userForm.password}
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-slate-900 px-1">Paket</td>
                        <td className="border border-slate-900 px-1">
                          {userForm.paketObj.value}
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-slate-900 px-1">Daerah</td>
                        <td className="border border-slate-900 px-1">
                          {userForm.daerahObj.value}
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-slate-900 px-1">Notify</td>
                        <td className="border border-slate-900 px-1">
                          {(!!userForm.notify).toString()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ),
            }}
          />
        );
      case "sendMessage":
        closeModal = () => {
          setUserForm({ ...emptyForm });
          setModalType(false);
        };
        return (
          <Modal
            title="Kirim pesan?"
            close={closeModal}
            body={
              <SendMessageForm
                messageSend={whatsapp.message}
                onSubmit={(msg) => {
                  setIsLoading(true);
                  instance
                    .post("send-message/" + userForm.id, { message: msg })
                    .then(() => {
                      setIsLoading(false);
                      closeModal();
                    })
                    .catch((e) =>
                      expirationHandler(e, () => {
                        setIsLoading(false);
                        closeModal();
                      })
                    );
                }}
              />
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeModal}>
                  Cancel
                </Button>
                <Button variant="dark" type="submit" form="message">
                  Send
                </Button>
              </div>
            }
          />
        );
      default:
        break;
    }
  };

  const openAddUserForm = () => {
    const now = new Date();
    now.setMonth(now.getMonth() + 1);
    const dates = [
      now.getFullYear(),
      twoDigits(now.getMonth() + 1),
      twoDigits(now.getDate()),
    ];
    setUserForm({ ...userForm, aktifHingga: dates.join("-") });
    setModalType("userForm");
  };

  const printData = (id) => {
    window.open(
      "/print/" + id + serialize({ token: sessionStorage.getItem("token") }),
      "_blank"
    );
  };

  const openBulkPerpanjang = () => {
    setUserForm({
      ...userForm,
      id: "bulk",
      nama: selected.length + " costumer",
    });
    setModalType("perpanjang");
  };

  const methods = {
    getParams,
    search: findCostumerStart,
  };
  const states = {
    searchInput: [searchInput, setSearchInput],
    page: [page, setPage],
    dataLength,
    setRows,
    sortBy: [sortBy, setSort],
    setIsLoading,
  };
  const leftHeader = (
    <Dropdown
      variant="dark"
      title="Manage Costumers"
      className="sm:w-auto w-full flex justify-between"
      containerClass="sm:w-auto w-full"
    >
      {isAdmin ? ( userData.role === "admin" ? (
        <div className="absolute pt-2 w-full z-[1]">
          <div className="w-full text-white rounded-lg border-gray-500 border bg-slate-700">
            <div
              onClick={
                isMobile
                  ? () =>
                      navigate("/costumer_form", {
                        state: { userData: { ...userForm } },
                      })
                  : openAddUserForm
              }
              className="py-1 px-4 cursor-pointer rounded-t-lg hover:bg-[#415166] transition "
            >
              <FontAwesomeIcon icon="user-plus" className="mr-3 w-4" />
              Tambah Pelanggan
            </div>
            <div
              onClick={() => setModalType("import")}
              className="py-1 px-4 cursor-pointer rounded-t-lg hover:bg-[#415166] transition "
            >
              <FontAwesomeIcon icon="file-import" className="mr-3 w-4" />
              Import Pelanggan
            </div>
            <div className="text-sm pl-3 border-b mt-2 border-gray-400">
              Bulk
            </div>
            <div
              className={
                "py-1 px-4 border-gray-500 transition " +
                (isSelected
                  ? "hover:bg-[#415166] cursor-pointer"
                  : "text-gray-400 cursor-default")
              }
              onClick={isSelected ? deleteBulk : () => {}}
            >
              <FontAwesomeIcon icon="user-minus" className="mr-3 w-4" />
              Hapus Pelanggan
            </div>
            <div
              className={
                "py-1 px-4 rounded-b-lg transition " +
                (isSelected
                  ? "hover:bg-[#415166] cursor-pointer"
                  : "text-gray-400 cursor-default")
              }
              onClick={isSelected ? openBulkPerpanjang : () => {}}
            >
              <FontAwesomeIcon icon="calendar-week" className="mr-2 w-4" />
              Perpanjang
            </div>
            <div
              className={
                "py-1 px-4 border-gray-500 transition " +
                (isSelected
                  ? "hover:bg-[#415166] cursor-pointer"
                  : "text-gray-400 cursor-default")
              }
              onClick={isSelected ? () => toggleStatuses(false) : () => {}}
            >
              <FontAwesomeIcon icon="user-xmark" className="mr-3 w-4" />
              Disable Pelanggan
            </div>
            <div
              className={
                "py-1 px-4 border-gray-500 transition " +
                (isSelected
                  ? "hover:bg-[#415166] cursor-pointer"
                  : "text-gray-400 cursor-default")
              }
              onClick={isSelected ? () => toggleStatuses(true) : () => {}}
            >
              <FontAwesomeIcon icon="user-check" className="mr-3 w-4" />
              Enable Pelanggan
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute pt-2 w-full z-[1]">
          <div className="w-full text-white rounded-lg border-gray-500 border bg-slate-700">
            <div className="text-sm pl-3 border-b mt-2 border-gray-400">
              Bulk
            </div>
            <div
              className={
                "py-1 px-4 border-gray-500 transition " +
                (isSelected
                  ? "hover:bg-[#415166] cursor-pointer"
                  : "text-gray-400 cursor-default")
              }
              onClick={isSelected ? () => toggleStatuses(false) : () => {}}
            >
              <FontAwesomeIcon icon="user-xmark" className="mr-3 w-4" />
              Disable Pelanggan
            </div>
            <div
              className={
                "py-1 px-4 border-gray-500 transition " +
                (isSelected
                  ? "hover:bg-[#415166] cursor-pointer"
                  : "text-gray-400 cursor-default")
              }
              onClick={isSelected ? () => toggleStatuses(true) : () => {}}
            >
              <FontAwesomeIcon icon="user-check" className="mr-3 w-4" />
              Enable Pelanggan
            </div>
          </div>
        </div>
      ) ) : (
        <div className="absolute pt-2 w-full z-[1]">
          <div className="w-full text-white rounded-lg border-gray-500 border bg-slate-700">
            <div className="text-sm pl-3 border-b mt-2 border-gray-400">
              Bulk
            </div>
            <div
              className={
                "py-1 px-4 rounded-b-lg transition " +
                (isSelected
                  ? "hover:bg-[#415166] cursor-pointer"
                  : "text-gray-400 cursor-default")
              }
              onClick={isSelected ? openBulkPerpanjang : () => {}}
            >
              <FontAwesomeIcon icon="calendar-week" className="mr-2 w-4" />
              Perpanjang
            </div>
            <div
              className={
                "py-1 px-4 border-gray-500 transition " +
                (isSelected
                  ? "hover:bg-[#415166] cursor-pointer"
                  : "text-gray-400 cursor-default")
              }
              onClick={isSelected ? () => toggleStatuses(false) : () => {}}
            >
              <FontAwesomeIcon icon="user-xmark" className="mr-3 w-4" />
              Disable Pelanggan
            </div>
            <div
              className={
                "py-1 px-4 border-gray-500 transition " +
                (isSelected
                  ? "hover:bg-[#415166] cursor-pointer"
                  : "text-gray-400 cursor-default")
              }
              onClick={isSelected ? () => toggleStatuses(true) : () => {}}
            >
              <FontAwesomeIcon icon="user-check" className="mr-3 w-4" />
              Enable Pelanggan
            </div>
          </div>
        </div>
      )}
    </Dropdown>
  );
  const rowAction = (data) => {
    const messageButton = whatsapp.status ? (
      <Button
        className="mx-0.5"
        onClick={(e) => {
          e.stopPropagation();
          setModalType("sendMessage");
          setUserForm({
            ...emptyForm,
            id: data.id,
          });
        }}
      >
        <FontAwesomeIcon icon="message" />
      </Button>
    ) : undefined;
    return isAdmin ? (userData.role === "admin" ? (
      <div>
        {messageButton}
        <Button
          className="mx-0.5"
          onClick={(e) => {e.stopPropagation(); printData(data.pembayaranId)}}
          disabled={!data.pembayaranId}
        >
          <FontAwesomeIcon icon="print" />
        </Button>
        <Button className="mx-0.5" onClick={(e) => {e.stopPropagation(); openPerpanjang(data)}}>
          <FontAwesomeIcon
            className="w-4 h-4"
            icon={data.lunas ? "bolt-lightning" : "money-bills"}
          />
        </Button>
        <Button
          className="mx-0.5"
          onClick={(e) => {
            e.stopPropagation();
            editCostumer(data);
          }}
        >
          <FontAwesomeIcon icon="pen-to-square" />
        </Button>
        <Button className="mx-0.5" onClick={(e) => {e.stopPropagation(); deleteCostumer(data)}}>
          <FontAwesomeIcon icon="trash" />
        </Button>
      </div>
    ) : (
      <div>
        <Button
          className="mx-0.5"
          onClick={(e) => {e.stopPropagation(); printData(data.pembayaranId)}}
          disabled={!data.pembayaranId}
        >
          <FontAwesomeIcon icon="print" />
        </Button>
      </div>
    ) ) : (
      <div>
        {messageButton}
        <Button
          className="mx-0.5"
          onClick={() => printData(data.pembayaranId)}
          disabled={!data.pembayaranId}
        >
          <FontAwesomeIcon icon="print" />
        </Button>
        <Button className="mx-0.5" onClick={(e) => {e.stopPropagation(); openPerpanjang(data)}}>
          <FontAwesomeIcon icon="bolt-lightning" />
        </Button>
      </div>
    );
  };

  return (
    <div>
      <LoadingScreen show={isLoading} />
      {isMobile ? (
        <TablePage
          movePageRequest="costumer"
          methods={methods}
          state={states}
          leftHeader={leftHeader}
          onMovePage={resetCheckbox}
          columns={{
            header: [
              { label: "Nama", column: "nama" },
              { label: "Tanggal Aktif", column: "tanggal_aktif" },
            ],
            defaultOrder: defaultOrder,
            row: rows.map((data, i) => (
              <CostumerRow
                key={data.id}
                data={data}
                methods={{ setFormToRow, setModalType, toggleStatus }}
                checkboxState={[checkboxes[i], (e) => setCheckbox(e, i)]}
                selected={selected.length > 0}
                isAdmin={isAdmin}
              />
            )),
          }}
        />
      ) : (
        <TablePage
          movePageRequest="costumer"
          methods={methods}
          state={states}
          leftHeader={leftHeader}
          onMovePage={resetCheckbox}
          columns={{
            header: [
              {
                label: (
                  <Checkbox
                    value={mainCheckbox}
                    onChange={(e) =>
                      setCheckboxes(
                        new Array(rows.length).fill(e.target.checked)
                      )
                    }
                  />
                ),
                checkbox: true,
              },
              { label: "Nama", column: "nama" },
              { label: "Username", column: "username" },
              { label: "KTP", column: "ktp" },
              { label: "Daerah", column: "Daerah" },
              { label: "Paket", column: "Paket" },
              { label: "Tanggal Aktif", column: "tanggal_aktif" },
              { label: "Action" },
              { label: "Status" },
            ],
            defaultOrder,
            row: rows.map((data, i) => {
              const columns = [
                <Checkbox
                  value={checkboxes[i]}
                  onChange={(e) => setCheckbox(e.target.checked, i)}
                />,
                data.nama,
                data.username,
                data.ktp,
                data.daerah.value,
                data.paket.value,
                formatDate(data.aktifHingga),
                rowAction(data),
                <div className="px-2 mx-3 flex justify-center">
                  <div
                    className={
                      "absolute right-0 top-0 w-1/6 h-full " +
                      (data.connected ? "bg-sky-200" : "bg-rose-200")
                    }
                  ></div>
                  <div
                    className={
                      "py-1 px-3 border border-white w-fit rounded-md cursor-pointer relative " +
                      (data.active
                        ? data.lunas
                          ? "text-sky-600 bg-sky-200"
                          : "text-lime-600 bg-lime-200"
                        : data.lunas
                        ? "text-red-600 bg-red-200"
                        : "text-amber-600 bg-amber-200")
                    }
                    onClick={() => toggleStatus(data, !data.active)}
                  >
                    {data.active ? "Active" : "Disabled"}
                  </div>
                </div>,
              ];
              return (
                <tr
                  key={data.id}
                  className="border-slate-400 even:bg-slate-300 odd:bg-[#bdcada] last:border-b-0 border-b border-t transition hover:bg-[#a7b9cd]"
                  onClick={() => { setFormToRow(data); setModalType("mobileDetail");}}
                >
                  {columns.map((x, idx) => (
                    <td
                      key={idx}
                      className={
                        "items-center font-medium pb-2 text-center pt-2" +
                        (idx === 7 || idx === 0
                          ? " w-[1%] whitespace-nowrap px-2"
                          : idx === 8
                          ? " relative w-[1%] whitespace-nowrap"
                          : "")
                      }
                    >
                      {x}
                    </td>
                  ))}
                </tr>
              );
            }),
          }}
        />
      )}
      <AnimatePresence>{CurrentModal()}</AnimatePresence>
    </div>
  );
}
