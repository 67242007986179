import { useState } from "react";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import { expirationHandler, instance } from "../../utils";
import Swal from "sweetalert2";

export default function Mikrotik({ connection, setIsLoading }) {
  const [[mikrotik, setMikrotik], [radius, setRadius], pools] = connection;
  const [isPool, setIsPool] = useState(true);

  const editConnection = (e) => {
    e.preventDefault();
    setIsLoading(true);
    instance
      .put("edit-connection", { mikrotik, radius })
      .then(() => {
        setIsLoading(false)
        Swal.fire({
          text: "Network setting telah dirubah",
          title: "Success",
          icon: "success"
        })
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  return (
    <div>
      <div className="px-3 py-1 bg-slate-800 text-xl text-white font-semibold">
        Mikrotik
      </div>
      <form onSubmit={editConnection} className="py-4 flex w-full">
        <div className="w-full px-4">
          <div className="text-lg font-semibold">Mikrotik Conncetion</div>
          <div className="flex flex-wrap">
            <div className="w-1/2">
              <div>Host</div>
              <TextInput
                value={mikrotik.host}
                onChange={(e) => setMikrotik("host", e.target.value)}
              />
            </div>
            <div className="w-1/2">
              <div>Username</div>
              <TextInput
                value={mikrotik.user}
                onChange={(e) => setMikrotik("username", e.target.value)}
              />
            </div>
            <div className="w-1/2">
              <div>Password</div>
              <TextInput
                value={mikrotik.pass}
                onChange={(e) => setMikrotik("password", e.target.value)}
              />
            </div>
          </div>
          <div className="text-lg font-semibold mt-5 border-t">Radius</div>
          <div className="flex flex-wrap">
            <div className="w-1/2">
              <div>Host</div>
              <TextInput
                value={radius.host}
                onChange={(e) => setRadius("host", e.target.value)}
              />
            </div>
            <div className="w-1/2">
              <div>Name</div>
              <TextInput
                value={radius.name}
                onChange={(e) => setRadius("name", e.target.value)}
              />
            </div>
            <div className="w-1/2">
              <div>Secret</div>
              <TextInput
                value={radius.secret}
                onChange={(e) => setRadius("secret", e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="w-full px-4 flex flex-col justify-between">
          <div className="flex w-full">
            <div className="w-full px-1">
              <Button
                onClick={() => setIsPool(true)}
                variant={isPool ? "dark" : "light"}
                className={
                  "w-full" +
                  (isPool ? " outline outline-gray-400 outline-3" : "")
                }
              >
                Active Pool
              </Button>
            </div>
            <div className="w-full px-1">
              <Button
                onClick={() => setIsPool(false)}
                variant={isPool ? "light" : "dark"}
                className={
                  "w-full" +
                  (isPool ? "" : " outline outline-gray-400 outline-3")
                }
              >
                Expiration Pool
              </Button>
            </div>
          </div>
          <table className="w-full my-4 bg-white border-slate-400">
            <thead>
              <tr className="bg-slate-200">
                <th className="border border-slate-400 font-semibold">Name</th>
                <th className="border border-slate-400 font-semibold">
                  Addresses
                </th>
              </tr>
            </thead>
            <tbody>
              {pools.map((pool) => (
                <tr
                  key={pool[".id"]}
                  className={
                    isPool
                      ? pool.name === mikrotik.pool
                        ? "bg-emerald-100 outline outline-gray-400 outline-2"
                        : pool.name === mikrotik.expPool
                        ? "bg-red-50"
                        : "hover:bg-amber-100 cursor-pointer"
                      : pool.name === mikrotik.expPool
                      ? "bg-red-100 outline outline-gray-400 outline-2"
                      : pool.name === mikrotik.pool
                      ? "bg-emerald-50"
                      : "hover:bg-amber-100 cursor-pointer"
                  }
                  onClick={() => {
                    if (isPool) {
                      if (pool.name === mikrotik.pool) return;
                      setMikrotik("pool", pool.name);
                    } else {
                      if (pool.name === mikrotik.expPool) return;
                      setMikrotik("expPool", pool.name);
                    }
                  }}
                >
                  <td className="text-center border border-slate-400">
                    {pool.name}
                  </td>
                  <td className="text-center border border-slate-400">
                    {pool.ranges}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <Button className="mb-0 ml-auto mr-0" variant="dark" type="submit">
              Save Changes
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
