import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import FileInput from "../../components/FileInput";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import {
  expirationHandler,
  instance,
  serialize,
  waitForToken,
} from "../../utils";
import TablePage from "../../components/TablePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "../../components/Dropdown";
import Checkbox from "../../components/Checkbox";
import { AnimatePresence } from "framer-motion";
import PaketFormBody from "./PaketFormBody";
import DataChange from "./DataChange";
import DaerahFormBody from "./DaerahFormBody";
import { useSelector } from "react-redux";

export default function DaerahPaket() {
  const emptyForm = {
    id: "",
    nama: "",
    harga: 0,
    bandwidth: "",
    hasPrefix: false,
  };
  const [modalType, setModalType] = useState(false);
  const [paketForm, setPaketForm] = useState({
    ...emptyForm,
  });
  const [paketRows, setPaketRows] = useState([]);
  const [paketPage, setPaketPage] = useState(1);
  const [paketLength, setPaketLength] = useState(0);
  const [paketSearch, setPaketSearch] = useState("");
  const [paketCheckboxes, setPaketCheckboxes] = useState([]);
  const [daerahRows, setDaerahRows] = useState([]);
  const [daerahPage, setDaerahPage] = useState(1);
  const [daerahLength, setDaerahLength] = useState(0);
  const [daerahSearch, setDaerahSearch] = useState("");
  const [daerahCheckboxes, setDaerahCheckboxes] = useState([]);
  const [deleteChoice, setDeleteChoice] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const defaultOrder = { column: "id", asc: true };
  const [daerahOrder, setDaerahOrder] = useState({ ...defaultOrder });
  const [paketOrder, setPaketOrder] = useState({ ...defaultOrder });
  const [fileInput, setFileInput] = useState("");
  const [prefix, setPrefix] = useState("")
  const edit = useRef(false);
  const user = useSelector(state => state.user)
  const daerahParams = (obj = {}) =>
    serialize({
      search: daerahSearch,
      page: daerahPage - 1,
      column: daerahOrder.column,
      direction: daerahOrder.asc,
      ...obj,
    });
  const paketParams = (obj = {}) =>
    serialize({
      search: paketSearch,
      page: paketPage - 1,
      column: paketOrder.column,
      direction: paketOrder.asc,
      ...obj,
    });

  const pageInit = () => {
    setIsLoading(true);
    instance
      .get("daerah-paket")
      .then(({ data }) => {
        const { paket, daerah } = data;
        setIsLoading(false);
        setDaerahResult(daerah);
        setPaketResult(paket);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };
  useEffect(() => {
    waitForToken(pageInit);
  }, []);

  useEffect(() => {
    if (user.role === "admin")
    instance.get("prefix").then(({data}) => {
      setPrefix(data)
    })
  }, [user])

  const resetDaerahCheckbox = () => {
    setDaerahCheckboxes(new Array(20).fill(false));
  };
  const resetPaketCheckbox = () => {
    setPaketCheckboxes(new Array(20).fill(false));
  };

  const setPaketResult = ({ value, length }) => {
    resetPaketCheckbox();
    setPaketRows(value);
    setPaketLength(length);
  };

  const setDaerahResult = ({ value, length }) => {
    resetDaerahCheckbox();
    setDaerahRows(value);
    setDaerahLength(length);
  };

  const showModal = () => {
    let closeForm;
    switch (modalType) {
      case "paketForm":
        closeForm = () => {
          setModalType(false);
          setPaketForm({
            id: "",
            nama: "",
            harga: 0,
            bandwidth: "",
          });
        };
        return (
          <Modal
            close={closeForm}
            title={(paketForm.id ? "Edit" : "Tambah") + " Paket"}
            body={
              <PaketFormBody
                onSubmit={paketForm.id ? editPaketConfirm : addPaket}
                modalForm={[paketForm, setPaketForm]}
                prefix={prefix}
              />
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeForm}>
                  Close
                </Button>
                <Button variant="dark" type="submit" form="paketForm">
                  {paketForm.id ? "Save" : "Add"}
                </Button>
              </div>
            }
          />
        );
      case "daerahForm":
        closeForm = () => {
          setModalType(false);
          setPaketForm({ ...emptyForm });
        };
        return (
          <Modal
            close={closeForm}
            title={(paketForm.id ? "Edit" : "Tambah") + " Daerah"}
            size="xs"
            body={
              <DaerahFormBody
                modalForm={[
                  paketForm.nama,
                  (x) => setPaketForm({ ...paketForm, nama: x }),
                ]}
                onSubmit={paketForm.id ? editDaerahConfirm : addDaerah}
              />
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeForm}>
                  Cancel
                </Button>
                <Button variant="dark" type="submit" form="daerahForm">
                  {paketForm.id ? "Save" : "Add"}
                </Button>
              </div>
            }
          />
        );
      case "deleteDaerah":
      case "deletePaket":
        const isPaket = modalType === "deletePaket";
        closeForm = () => {
          setModalType(false);
          setDeleteChoice(true);
          setPaketForm({ ...emptyForm });
        };
        return (
          <Modal
            close={closeForm}
            title={`Hapus ${isPaket ? "Paket" : "Daerah"} ${paketForm.nama}`}
            body={
              <div>
                <div className="flex">
                  <div className="w-full pr-1">
                    <Button
                      className="w-full"
                      onClick={() => setDeleteChoice(true)}
                      variant={deleteChoice ? "dark" : "light"}
                    >
                      Ganti {isPaket ? "paket" : "daerah"}
                    </Button>
                  </div>
                  <div className="w-full pl-1">
                    <Button
                      className="w-full"
                      onClick={() => {
                        setDeleteChoice(false);
                      }}
                      variant={!deleteChoice ? "dark" : "light"}
                    >
                      Hapus pelanggan
                    </Button>
                  </div>
                </div>
                {deleteChoice ? (
                  <DataChange
                    changeState={[
                      paketForm.bandwidth,
                      (x) => setPaketForm({ ...paketForm, bandwidth: x }),
                    ]}
                    isPaket={isPaket}
                    deletePaket={deletePaket}
                    deleteDaerah={deleteDaerah}
                    remove={paketForm.id}
                  />
                ) : undefined}
              </div>
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeForm}>
                  Cancel
                </Button>
                <Button
                  variant="dark"
                  type="submit"
                  onClick={() => (isPaket ? deletePaket() : deleteDaerah())}
                  form="deleteForm"
                >
                  Confirm
                </Button>
              </div>
            }
          />
        );
      case "import":
        const closeImport = () => {
          setModalType(false);
          setFileInput("");
        };

        return (
          <Modal
            close={closeImport}
            title="Import Pelanggan"
            body={
              <form id="importForm" onSubmit={importPaket}>
                <FileInput
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  value={fileInput}
                  onChange={(e) => setFileInput(e.target.files[0])}
                  required
                />
              </form>
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeImport}>
                  Cancel
                </Button>
                <Button variant="dark" type="submit" form="importForm">
                  Submit
                </Button>
              </div>
            }
          />
        );
      default:
        return undefined;
    }
  };
  const getPaketStart = (search) => {
    setIsLoading(true);
    const param = {}
    if (search) {
      param.page = 0;
      setPaketPage(1)
    }
    instance
      .get("paket" + paketParams(param))
      .then(({ data }) => {
        setIsLoading(false);
        setPaketResult(data);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const editPaketConfirm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      harga: paketForm.harga,
      bandwidth: paketForm.bandwidth,
      nama: paketForm.nama,
    }
    if (paketForm.hasPrefix) payload.nama = prefix + "_" + paketForm.nama
    instance
      .put("paket/" + paketForm.id, payload)
      .then(({ data }) => {
        setIsLoading(false);
        setPaketRows(paketRows.map((row) => (row.id === data.id ? data : row)));
        setModalType(false);
        setPaketForm({
          id: "",
          nama: "",
          harga: 0,
          bandwidth: "",
        });
        Swal.fire({
          title: "Berhasil Mengubah Paket!",
          icon: "success",
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };
  const addPaket = (e) => {
    e.preventDefault();
    setIsLoading(true);
    instance
      .post("paket" + paketParams(), paketForm)
      .then(({ data }) => {
        setIsLoading(false);
        setModalType(false);
        setPaketResult(data);
        setPaketForm({
          id: "",
          nama: "",
          harga: 0,
          bandwidth: "",
        });
        Swal.fire({
          title: "Berhasil Menambahkan Paket!",
          icon: "success",
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };
  const editPaket = (paket) => {
    edit.current = true;
    setPaketForm({
      id: paket.id,
      nama: paket.nama.includes(prefix) ? paket.nama.split("_")[1] : paket.nama,
      harga: paket.harga,
      bandwidth: paket.bandwidth,
      hasPrefix: paket.nama.includes(prefix)
    });
    setModalType("paketForm");
  };
  const askDeletePaket = (paket) => {
    setPaketForm({ ...paketForm, id: paket.id, nama: paket.nama });
    setModalType(false);
    Swal.fire({
      title: "Hapus Paket?",
      text: 'Apakah anda yakin ingin menghapus paket "' + paket.nama + '" ?',
      icon: "question",
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) return setModalType("deletePaket");
      setPaketForm({ ...emptyForm });
    });
  };
  const deletePaket = (e) => {
    setIsLoading(true);
    const customParams = { delete: paketForm.id };
    if (deleteChoice) {
      if (!paketForm.bandwidth) return setIsLoading(false);
      customParams.change = paketForm.bandwidth;
    }
    const noData = paketRows.length < 2 && paketPage !== 1;
    if (noData) {
      customParams.page = paketPage - 2;
    }
    instance
      .delete(
        (deleteChoice ? "paket-change" : "paket-delete") +
          paketParams(customParams)
      )
      .then(({ data }) => {
        setIsLoading(false);
        setModalType(false);
        if (noData) {
          setPaketPage(paketPage - 1);
        }
        setPaketResult(data);
        setPaketForm({ ...emptyForm });
        Swal.fire({
          title: "Berhasil Menghapus Paket!",
          icon: "success",
        });
        setDeleteChoice(true);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const getDaerahStart = (search) => {
    setIsLoading(true);
    const param = {}
    if (search) {
      param.page = 0;
      setDaerahPage(1);
    }
    instance
      .get("daerah" + daerahParams(param))
      .then(({ data }) => {
        setIsLoading(false);
        setDaerahResult(data);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const editDaerahConfirm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    instance
      .put("daerah/" + paketForm.id + serialize({ nama: paketForm.nama }))
      .then(({ data }) => {
        setIsLoading(false);
        setDaerahRows(
          daerahRows.map((row) => {
            if (row.id === data.id) return data;
            return row;
          })
        );
        setModalType(false);
        setPaketForm({
          ...paketForm,
          id: "",
          nama: "",
        });
        Swal.fire({
          title: "Berhasil Mengubah Daerah!",
          icon: "success",
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const addDaerah = (e) => {
    e.preventDefault();
    setIsLoading(true);
    instance
      .post("daerah" + daerahParams({ nama: paketForm.nama }))
      .then(({ data }) => {
        setIsLoading(false);
        setModalType(false);
        setDaerahResult(data);
        setPaketForm({ ...emptyForm });
        Swal.fire({
          title: "Berhasil Menambahkan Daerah!",
          icon: "success",
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };
  const editDaerah = (daerah) => {
    setPaketForm({ ...paketForm, nama: daerah.name, id: daerah.id });
    setModalType("daerahForm");
  };
  const askDeleteDaerah = (daerah) => {
    setModalType(false);
    Swal.fire({
      title: "Hapus Daerah?",
      text: 'Apakah anda yakin ingin menghapus daerah "' + daerah.name + '" ?',
      icon: "question",
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;
      setPaketForm({ ...paketForm, id: daerah.id, nama: daerah.name });
      setModalType("deleteDaerah");
    });
  };

  const deleteDaerah = (e) => {
    setIsLoading(true);
    const customParams = { delete: paketForm.id };
    if (deleteChoice) {
      if (!paketForm.bandwidth) return setIsLoading(false);
      customParams.change = paketForm.bandwidth;
    }
    const noData = daerahRows.length < 2 && daerahPage !== 1;
    if (noData) {
      customParams.page = daerahPage - 2;
    }
    setIsLoading(true);
    instance
      .delete(
        (deleteChoice ? "daerah-change" : "daerah-delete") +
          daerahParams(customParams)
      )
      .then(({ data }) => {
        setIsLoading(false);
        setModalType(false);
        setDaerahResult(data);
        if (noData) {
          setDaerahPage(daerahPage - 1);
        }
        setPaketForm({ ...emptyForm });
        Swal.fire({
          title: "Berhasil Menghapus Daerah!",
          icon: "success",
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };
  const paketBulkDelete = (selected) => {
    Swal.fire({
      title: "Hapus Paket?",
      text: "Apakah anda yakin ingin menghapus " + selected.length + " paket ?",
      icon: "question",
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;
      setIsLoading(true);
      const customParams = { ids: selected.map((x) => paketRows[x].id) };
      const noData =
        paketCheckboxes.every(Boolean) &&
        paketPage === paketLength &&
        paketPage !== 1;
      if (noData) {
        customParams.page = paketPage - 2;
      }
      instance
        .delete("bulk-paket" + paketParams(customParams))
        .then(({ data }) => {
          setIsLoading(false);
          setPaketResult(data);
          if (noData) {
            setPaketPage(paketPage - 1);
          }
          Swal.fire({
            icon: "success",
            text: "Berhasil menghapus paket",
          });
        })
        .catch((e) => expirationHandler(e, setIsLoading));
    });
  };

  const daerahBulkDelete = (selected) => {
    Swal.fire({
      title: "Hapus Daerah?",
      text:
        "Apakah anda yakin ingin menghapus " + selected.length + " daerah ?",
      icon: "question",
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;
      const customParams = { ids: selected.map((x) => daerahRows[x].id) };
      const noData =
        daerahCheckboxes.every(Boolean) &&
        daerahPage === daerahLength &&
        daerahPage !== 1;
      if (noData) {
        customParams.page = daerahPage - 2;
      }
      setIsLoading(true);
      instance
        .delete("bulk-daerah" + daerahParams(customParams))
        .then(({ data }) => {
          setIsLoading(false);
          setDaerahResult(data);
          if (noData) {
            setDaerahPage(daerahPage - 1);
          }
          Swal.fire({
            icon: "success",
            text: "Berhasil menghapus daerah",
          });
        })
        .catch((e) => expirationHandler(e, setIsLoading));
    });
  };
  const importPaket = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("search", paketSearch);
    formData.append("page", paketPage);
    formData.append("file", fileInput);
    formData.append("column", paketOrder.column);
    formData.append("direction", paketOrder.asc);
    instance
      .postForm("import-paket", formData)
      .then(({ data }) => {
        setPaketResult(data);
        setIsLoading(false);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const paketCheckSelected = () => {
    const res = [];
    paketCheckboxes.forEach((x, idx) => {
      if (x) res.push(idx);
    });
    return res;
  };
  const paketSelected = paketCheckSelected();
  const paketIsSelected = paketSelected.length > 0;
  const daerahCheckSelected = () => {
    const res = [];
    daerahCheckboxes.forEach((x, idx) => {
      if (x) res.push(idx);
    });
    return res;
  };
  const daerahSelected = daerahCheckSelected();
  const daerahIsSelected = daerahSelected.length > 0;

  const setPaketCheckbox = (val, idx) => {
    setPaketCheckboxes(paketCheckboxes.map((x, i) => (idx === i ? val : x)));
  };
  const setDaerahCheckbox = (val, idx) => {
    setDaerahCheckboxes(daerahCheckboxes.map((x, i) => (idx === i ? val : x)));
  };
  const mainPaketCheckbox =
    paketCheckboxes.length > 0 ? paketCheckboxes.every(Boolean) : false;
  const mainDaerahCheckbox =
    daerahCheckboxes.length > 0 ? daerahCheckboxes.every(Boolean) : false;

  return (
    <div className="flex h-screen">
      <LoadingScreen show={isLoading} />
      <div className="w-[60%]">
        <TablePage
          movePageRequest="paket"
          onMovePage={resetPaketCheckbox}
          state={{
            searchInput: [paketSearch, setPaketSearch],
            page: [paketPage, setPaketPage],
            dataLength: paketLength,
            setRows: setPaketRows,
            sortBy: [paketOrder, setPaketOrder],
            setIsLoading,
          }}
          methods={{
            getParams: paketParams,
            search: getPaketStart,
          }}
          leftHeader={
            <Dropdown variant="dark" title="Manage Paket">
              <div className="absolute pt-2 w-full z-[1]">
                <div className="w-full text-white rounded-lg border-gray-500 border bg-slate-700">
                  <div
                    onClick={() => setModalType("paketForm")}
                    className="py-1 px-4 cursor-pointer rounded-t-lg hover:bg-[#415166] transition "
                  >
                    <FontAwesomeIcon icon="plus-circle" className="mr-3 w-4" />
                    Tambah Paket
                  </div>
                  <div
                    onClick={() => setModalType("import")}
                    className="py-1 px-4 cursor-pointer rounded-t-lg hover:bg-[#415166] transition "
                  >
                    <FontAwesomeIcon icon="file-import" className="mr-3 w-4" />
                    Import Paket
                  </div>
                  <div className="text-sm pl-3 border-b mt-2 border-gray-400">
                    Bulk
                  </div>
                  <div
                    className={
                      "py-1 px-4 border-gray-500 transition " +
                      (paketIsSelected
                        ? "hover:bg-[#415166] cursor-pointer"
                        : "text-gray-400 cursor-default")
                    }
                    onClick={
                      paketIsSelected
                        ? () => paketBulkDelete(paketSelected)
                        : () => {}
                    }
                  >
                    <FontAwesomeIcon icon="minus-circle" className="mr-3 w-4" />
                    Hapus Paket
                  </div>
                </div>
              </div>
            </Dropdown>
          }
          columns={{
            header: [
              {
                label: (
                  <Checkbox
                    value={mainPaketCheckbox}
                    onChange={(e) =>
                      setPaketCheckboxes(
                        new Array(paketRows.length).fill(e.target.checked)
                      )
                    }
                  />
                ),
                checkbox: true,
              },
              { label: "Nama", column: "groupname" },
              { label: "Harga", column: "price" },
              { label: "Bandwidth" },
              { label: "Actions" },
            ],
            defaultOrder: { column: "id", asc: false },

            row: paketRows.map((row, idx) => {
              const cols = [
                <Checkbox
                  value={paketCheckboxes[idx]}
                  onChange={(e) => setPaketCheckbox(e.target.checked, idx)}
                />,
                row.nama,
                row.harga,
                row.bandwidth,
                <div>
                  <Button className="mx-0.5" onClick={() => editPaket(row)}>
                    <FontAwesomeIcon icon="pen-to-square" />
                  </Button>
                  <Button
                    className="mx-0.5"
                    onClick={() => askDeletePaket(row)}
                  >
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </div>,
              ];
              return (
                <tr
                  key={row.id}
                  className="border-slate-400 even:bg-slate-300 transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] last:border-b-0 border-b border-t"
                >
                  {cols.map((col, i) => (
                    <td
                      key={i}
                      className={
                        "items-center font-medium pb-2 text-center pt-2" +
                        (i === 0 ? " w-[1%] whitespace-nowrap px-2" : "")
                      }
                    >
                      {col}
                    </td>
                  ))}
                </tr>
              );
            }),
          }}
        />
      </div>
      <div className="w-[40%]">
        <TablePage
          movePageRequest="daerah"
          state={{
            searchInput: [daerahSearch, setDaerahSearch],
            page: [daerahPage, setDaerahPage],
            dataLength: daerahLength,
            setRows: setDaerahRows,
            sortBy: [daerahOrder, setDaerahOrder],
            setIsLoading,
          }}
          methods={{
            getParams: daerahParams,
            search: getDaerahStart,
          }}
          onMovePage={resetDaerahCheckbox}
          leftHeader={
            <Dropdown variant="dark" title="Manage Daerah">
              <div className="absolute pt-2 w-full z-[1]">
                <div className="w-full text-white rounded-lg border-gray-500 border bg-slate-700">
                  <div
                    onClick={() => setModalType("daerahForm")}
                    className="py-1 px-4 cursor-pointer rounded-t-lg hover:bg-[#415166] transition "
                  >
                    <FontAwesomeIcon icon="plus-circle" className="mr-3 w-4" />
                    Tambah Daerah
                  </div>
                  <div className="text-sm pl-3 border-b mt-2 border-gray-400">
                    Bulk
                  </div>
                  <div
                    className={
                      "py-1 px-4 border-gray-500 transition " +
                      (daerahIsSelected
                        ? "hover:bg-[#415166] cursor-pointer"
                        : "text-gray-400 cursor-default")
                    }
                    onClick={
                      daerahIsSelected
                        ? () => daerahBulkDelete(daerahSelected)
                        : () => {}
                    }
                  >
                    <FontAwesomeIcon icon="circle-minus" className="mr-3 w-4" />
                    Hapus Daerah
                  </div>
                </div>
              </div>
            </Dropdown>
          }
          columns={{
            header: [
              {
                label: (
                  <Checkbox
                    value={mainDaerahCheckbox}
                    onChange={(e) =>
                      setDaerahCheckboxes(
                        new Array(daerahRows.length).fill(e.target.checked)
                      )
                    }
                  />
                ),
                checkbox: true,
              },
              { label: "Daerah", column: "name" },
              { label: "Actions" },
            ],
            defaultOrder: { column: "id", asc: false },
            row: daerahRows.map((row, idx) => {
              const cols = [
                <Checkbox
                  value={daerahCheckboxes[idx]}
                  onChange={(e) => setDaerahCheckbox(e.target.checked, idx)}
                />,
                row.name,
                <div>
                  <Button className="mx-0.5" onClick={() => editDaerah(row)}>
                    <FontAwesomeIcon icon="pen-to-square" />
                  </Button>
                  <Button
                    className="mx-0.5"
                    onClick={() => askDeleteDaerah(row)}
                  >
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </div>,
              ];
              return (
                <tr
                  className="border-slate-400 even:bg-slate-300 transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] last:border-b-0 border-b border-t"
                  key={row.id}
                >
                  {cols.map((col, i) => (
                    <td
                      className={
                        "items-center font-medium pb-2 text-center pt-2" +
                        (i === 0 ? " w-[1%] whitespace-nowrap px-2" : "")
                      }
                      key={i}
                    >
                      {col}
                    </td>
                  ))}
                </tr>
              );
            }),
          }}
          smallSearch
        />
      </div>
      <AnimatePresence>{showModal()}</AnimatePresence>
    </div>
  );
}
